import { Controller } from '@hotwired/stimulus';
import jQuery from 'jquery';
import 'fancybox';

// noinspection JSUnusedGlobalSymbols
/**
 * @property {boolean} reloadOnSuccessValue
 * @property {number} reloadOnSuccessTimeoutValue
 * @property {boolean} closeOnTurboFormSuccessValue
 */

export default class extends Controller {
    static values = {
        closeOnTurboFormSuccess: Boolean,
        reloadOnSuccess: Boolean,
        reloadOnSuccessTimeout: Number,
    };

    /**
     * @type {JQuery|null}
     */
    $outer = null;

    initialize() {
        this.onClick = this.onClick.bind(this);
        this.turboFormSubmitted = this.turboFormSubmitted.bind(this);
    }

    connect() {
        this.element.addEventListener('click', this.onClick);
    }

    disconnect() {
        this.element.removeEventListener('click', this.onClick);
    }

    /**
     * @param {MouseEvent} event
     */
    onClick(event) {
        const href = this.element.href ?? this.element.dataset.href ?? null;
        if (href === null) {
            return;
        }

        event.preventDefault();

        const options = Object.assign({}, CONFIG.FANCYBOX, {
            href,
            type: 'ajax',
            ajax: {
                type: 'get'
            },
            width: 1000,
            wrapCSS: "styled-fancybox overflow-visible",
            helpers: {
                overlay: {
                    closeClick: false
                }
            },
            afterLoad: this.afterLoad.bind(this),
            beforeClose: this.beforeClose.bind(this),
            afterClose: this.afterClose.bind(this),
        });

        jQuery.fancybox(options);
    }

    /**
     * @param {{ outer: jQuery|null }} instance
     * @property {function} $outer.on
     */
    afterLoad(instance) {
        this.$outer = instance.outer;
        /**
         * @property {function} $outer.on
         */
        this.$outer.on('turbo:submit-end', this.turboFormSubmitted);
    }

    beforeClose() {
        /**
         * @property {function} $outer.off
         */
        this.$outer.off('turbo:submit-end', this.turboFormSubmitted);
        this.$outer = null;
    }

    afterClose() {
        if (this.reloadOnSuccessValue) {
            setTimeout(
                () => window.location.reload(),
                this.reloadOnSuccessTimeoutValue
            );
        }
    }

    /**
     * @param {{ originalEvent: CustomEvent<{ success: boolean }>}} event
    */
    turboFormSubmitted(event) {
        if (
            this.closeOnTurboFormSuccessValue &&
            event.originalEvent.detail.success
        ) {
            jQuery.fancybox.close();
        }
    }
}
