import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';
import swal from 'sweetalert';

/**
 * @property {string} typeValue
 * @property {string} titleValue
 * @property {string} messageValue
 * @property {string} okValue
 * @property {string} cancelValue
 */
export default class extends Controller {
    static values = {
        type: String,
        title: String,
        message: String,
        ok: String,
        cancel: String,
    };

    initialize() {
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onTurboBeforeFetchRequest = this.onTurboBeforeFetchRequest.bind(this);
    }

    connect() {
        this.element.addEventListener('submit', this.onFormSubmit);
        this.element.addEventListener('turbo:before-fetch-request', this.onTurboBeforeFetchRequest);
    }

    disconnect() {
        this.element.removeEventListener('submit', this.onFormSubmit);
        this.element.removeEventListener('turbo:before-fetch-request', this.onTurboBeforeFetchRequest);
    }

    /**
     * @param {Event} event
     */
    async onFormSubmit(event) {
        if (Turbo.session.elementDriveEnabled(this.element)) {
            return;
        }

        if (this.isConfirmed) {
            this.isConfirmed = false;
            this.element.dispatchEvent(new CustomEvent('app:form-confirm-submit', {
                bubbles: true,
            }));

            return;
        }

        event.preventDefault();

        if (await this.swal()) {
            this.isConfirmed = true;
            this.element.submit();
        }
    }

    /**
     * @param {CustomEvent<{ resume() }>} event
     */
    async onTurboBeforeFetchRequest(event) {
        event.preventDefault();

        const isConfirm = await this.swal();

        if (isConfirm) {
            event.detail.resume();
        }
    }

    buildConfirmOptions() {
        const options = {
            showCancelButton: true,
        };

        if (this.typeValue) {
            options['type'] = this.typeValue;
        }

        if (this.titleValue) {
            options['title'] = this.titleValue;
        }

        if (this.messageValue) {
            options['text'] = this.messageValue;
        }

        if (this.okValue) {
            options['confirmButtonText'] = this.okValue;
        }

        if (this.cancelValue) {
            options['cancelButtonText'] = this.cancelValue;
        }

        return options;
    }

    swal() {
        return new Promise(
            resolve => swal(
                this.buildConfirmOptions(),
                isConfirm => resolve(isConfirm)
            )
        );
    }
}
