import { Controller } from "@hotwired/stimulus"
import * as Turbo from '@hotwired/turbo'

/**
 * @property {String} urlValue
 * @property {Array} transferredParams
 */
export default class extends Controller {
    static values = {
        url: String,
        transferredParams: Array
    };

    /**
     * @type {?string}
     */
    url = null;

    /**
     * @type {?string}
     */
    action = null;

    initialize() {
        this.onClick = this.onClick.bind(this);
        this.beforeVisit = this.beforeVisit.bind(this);
        this.onSubmitEnd = this.onSubmitEnd.bind(this);
        this.onFrameLoad = this.onFrameLoad.bind(this);
    }

    connect() {
        this.element.addEventListener('turbo:click', this.onClick);
        this.element.addEventListener('turbo:submit-end', this.onSubmitEnd);
        this.element.addEventListener('turbo:frame-load', this.onFrameLoad);
        document.addEventListener('turbo:before-visit', this.beforeVisit);
    }

    disconnect() {
        this.element.removeEventListener('turbo:click', this.onClick);
        this.element.addEventListener('turbo:submit-end', this.onSubmitEnd);
        this.element.removeEventListener('turbo:frame-load', this.onFrameLoad);
        document.removeEventListener('turbo:before-visit', this.beforeVisit);
    }

    /**
     * @param {CustomEvent<{ url: string }>} event
     */
    onClick(event) {
        const url = event.detail.url;
        if (this.isAnchoredUrl(url)) {
            this.url = url;
            this.action = Turbo.session.getActionForLink(event.target);
        }
    }

    /**
     * 
     * @param {CustomEvent<{ fetchResponse: { response: { url: URL } } }>} event
     */
    onSubmitEnd(event) {
        const url = event.detail.fetchResponse.response.url.toString();
        if (this.isAnchoredUrl(url)) {
            this.url = url;
            this.action = 'replace';
        }
    }

    /**
     * @param {string} url
     */
    isAnchoredUrl(url) {
        if (!this.urlValue) {
            return true;
        }

        let urlObject = new URL(url);
        urlObject.search = '';

        return this.urlValue === urlObject.toString();
    }

    /**
     * @param {CustomEvent<{ url: string }>} event
     */
    beforeVisit(event) {
        if (event.detail.url === this.url) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.isAnchoredUrl(event.detail.url)) {
            this.visitWithoutRender(this.url, this.action);
        }

        this.url = null;
        this.action = null;
    }

    /**
     * @param {CustomEvent} event
     */
    onFrameLoad(event) {
        if (this.element !== event.target || this.url !== null) {
            return;
        }

        if (this.isAnchoredUrl(this.element.src)) {
            this.visitWithoutRender(this.element.src, 'replace');
        }
    }

    /**
     * @param {string} url
     * @param {string} action
     */
    visitWithoutRender(url, action) {
        const here = new URL(window.location);
        const to = new URL(url);

        for (const param of this.transferredParamsValue) {
            const value = to.searchParams.get(param);

            if (value) {
                here.searchParams.set(param, value);
            } else {
                here.searchParams.delete(param);
            }
        }

        Turbo.visit(here, {
            action: action,
            willRender: false,
        });
    }
}
