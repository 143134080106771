import { Controller } from '@hotwired/stimulus';
import Spinner from 'spin.js';

// noinspection JSUnusedGlobalSymbols
/**
 * @callback SpinnerSpinMethod
 * @param {Element} element
 *
 * @callback SpinnerStopMethod
 *
 * @typedef {Object} Spinner
 * @property {SpinnerSpinMethod} spin
 * @property {SpinnerStopMethod} stop
 */
export default class extends Controller {
    /**
     * @type {Spinner}
     */
    spinner;

    connect() {
        this.spinner = new Spinner({
            lines: 12,
            length: 0,
            width: 8,
            radius: 16,
            scale: 1,
            corners: 1,
            color: '#DF2424',
            opacity: 0.3,
            rotate: 0,
            direction: 1,
            speed: 1,
            trail: 60,
            fps: 20,
            zIndex: 100,
            className: 'spinner',
            top: '50%',
            left: '50%',
            shadow: false,
            hwaccel: false,
            position: 'absolute',
        });

        this.spinner.spin(this.element);
    }

    disconnect() {
        this.spinner.stop()
    }
}
