import { Controller } from "@hotwired/stimulus"

const DESKTOP = '<i class="fa-solid fa-display"></i>';
const TABLET = '<i class="fa-solid fa-tablet-screen-button"></i>';
const MOBILE = '<i class="fa-solid fa-mobile-screen"></i>';
const WEB = '<i class="fa-brands fa-chrome"></i>';
const ANDROID = '<i class="fa-brands fa-android"></i>';
const IOS = '<i class="fa-brands fa-apple"></i>';
const OFFLINE = '<i class="fa-brands fa-buffer"></i>';

const ICONS = {
    WEB: WEB,
    WEB_DESKTOP: WEB + ' ' + DESKTOP,
    WEB_TABLET: WEB + ' ' + TABLET,
    WEB_MOBILE: WEB + ' ' + MOBILE,
    ANDROID: ANDROID,
    ANDROID_MOBILE: ANDROID + ' ' + MOBILE,
    ANDROID_TABLET: ANDROID + ' ' + TABLET,
    ANDROID_OFFLINE: ANDROID + ' ' + OFFLINE,
    IOS: IOS + ' ' + MOBILE,
};

export default class extends Controller {
    static values = {
        type: String,
        version: String,
        reverse: Boolean,
    };

    static targets = [
        'icon',
        'version',
    ];

    connect() {
        const icon = ICONS[this.typeValue] || this.typeValue;
        if (this.hasIconTarget && icon) {
            this.iconTarget.innerHTML = icon;
        }

        if (this.hasVersionTarget && this.versionValue) {
            this.versionTarget.innerHTML = this.versionValue;
        }

        if (!this.hasIconTarget && !this.hasVersionTarget) {
            const parts = [];
            if (icon) {
                parts.push(icon);
            }
            if (this.versionValue) {
                parts.push(this.versionValue);
            }
            this.element.innerHTML = parts.join(' ');
        }
    }
}
