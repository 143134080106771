import { Controller } from "@hotwired/stimulus"
import jQuery from 'jquery';
import 'chosen-js';

// noinspection JSUnusedGlobalSymbols
export default class extends Controller {
    static targets = [
        'select',
    ];

    /**
     * @type {?jQuery}
     */
    $select = null;

    /**
     * @type {?jQuery}
     */
    $filter = null;

    /**
     * @type {?JQuery}
     */
    $options = null;

    /**
     * @type {?string}
     */
    filterValue = null;

    /**
     * @type {?string}
     */
    filterSelector = null;

    /**
     * @type {?string}
     */
    filterDataProperty = null;

    initialize() {
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    /**
     * @param {Element} target
     */
    selectTargetConnected(target) {
        this.$select = jQuery(target);

        this.$select.chosen($.extend({}, CONFIG.CHOSEN));

        this.initializeFiltration();
    }

    disconnect() {
        if (this.$filter) {
            this.$filter.off('change', this.onFilterChange);
        }

        this.$select.chosen("destroy");
    }

    initializeFiltration() {
        if (!this.$select) {
            return;
        }

        this.filterSelector = this.$select.data('chosen-filter-target');
        this.filterDataProperty = this.$select.data('chosen-filter-data-property');

        if (!this.filterSelector || !this.filterDataProperty) {
            return;
        }

        this.$options = this.$select.find('option');
        this.$filter = jQuery(this.filterSelector);
        this.filterValue = this.$filter.val();

        this.$filter.on('change', this.onFilterChange);

        this.updateOptions();
    }

    onFilterChange() {
        const filterValue = this.$filter.val();
        if (this.filterValue === filterValue) {
            return;
        }

        this.filterValue = filterValue;

        this.updateOptions();
    }

    updateOptions() {
        if (!this.$options || !this.$select) {
            return;
        }

        const $filteredOptions = this.$options.filter((index, option) => {
            let values = jQuery(option).data(this.filterDataProperty);
            if (!Array.isArray(values)) {
                values = [values];
            }

            for (const value of values) {
                if (value === Number(this.filterValue)) {
                    return true;
                }
            }

            return false;
        });

        this.$options.detach();
        this.$select.append($filteredOptions);
        this.$select.trigger('chosen:updated');
    }
}
