import { Controller } from '@hotwired/stimulus';
import jQuery from 'jquery';
import 'jquery.maskedinput';

/**
 * @property {string} patternValue
 */
export default class extends Controller {
    static values = {
        pattern: String
    };

    connect() {
        setTimeout(() => jQuery(this.element).mask(this.patternValue));
    }

    disconnect() {
        jQuery(this.element).unmask();
    }
}
