import { Controller } from '@hotwired/stimulus';
import * as jQuery from 'jquery';
import 'bootstrap';

/**
 * @property {string} modalIdValue
 * @property {string} titleValue
 * @property {string} descriptionValue
 * @property {string} frameIdValue
 */
export default class extends Controller {
    static values = {
        modalId: String,
        title: String,
        description: String,
        frameId: String,
    };

    /**
     * @type {?jQuery}
     */
    $modal;

    /**
     * @type {?String}
     */
    formUrl;

    initialize() {
        this.onClick = this.onClick.bind(this);
        this.onSubmitEnd = this.onSubmitEnd.bind(this);
        this.beforeVisit = this.beforeVisit.bind(this);
    }

    connect() {
        this.$modal = jQuery(document.getElementById(this.modalIdValue));
        this.element.addEventListener('click', this.onClick);
        document.addEventListener('turbo:before-visit', this.beforeVisit);
    }

    disconnect() {
        document.removeEventListener('turbo:before-visit', this.beforeVisit);
        this.element.removeEventListener('click', this.onClick);
        this.hideModal();
        this.$modal = null;
    }

    async onClick(event) {
        event.preventDefault();

        let $modal = this.$modal;

        $modal.find('.modal-header .modal-title')
            .text(this.titleValue);

        $modal.find('.modal-header .modal-description')
            .text(this.descriptionValue);

        let $frame = $modal.find('.modal-body turbo-frame')
            .attr('id', this.frameIdValue)
            .attr('src', jQuery(this.element).attr('href'));

        let frame = $frame[0];

        await this.untilTurboFrameLoad(frame);

        $modal.one('hidden.bs.modal', () => {
            $modal.off('turbo:submit-end', this.onSubmitEnd);
        });

        $modal.on('turbo:submit-end', this.onSubmitEnd);

        await this.showModal();
    }

    /**
     * @param {{ originalEvent: CustomEvent<{
     *     success: boolean,
     *     fetchResponse: {
     *         response: {
     *             url: string
     *         }
     *     }
     * }>}} event
     */
    onSubmitEnd(event) {
        if (event.originalEvent.detail.success) {
            this.formUrl = event.originalEvent.detail.fetchResponse.response.url;

            this.element.dispatchEvent(new CustomEvent('app:modal-editor-success', {
                bubbles: true,
            }));

            this.hideModal();
        }
    }

    /**
     * @param {CustomEvent<{ url:string }>} event
     */
    beforeVisit(event) {
        const visitUrl = event.detail.url;
        if (visitUrl === this.formUrl && visitUrl !== window.location.href) {
            console.log(visitUrl, this.formUrl, window.location.href);
            event.preventDefault();
        }
        this.formUrl = null;
    }

    /**
     * @returns {Promise}
     */
    showModal() {
        if (!this.$modal) {
            return Promise.reject();
        }

        if (this.isModalShown) {
            return Promise.resolve();
        }

        const $modal = this.$modal;
        return new Promise(function (resolve) {
            $modal.one('show.bs.modal', resolve);
            $modal.modal('show');
        });
    }

    /**
     * @returns {Promise}
     */
    hideModal() {
        if (!this.$modal) {
            return Promise.reject();
        }

        if (!this.isModalShown) {
            return Promise.resolve();
        }

        const $modal = this.$modal;

        return new Promise(function (resolve) {
            $modal.one('hidden.bs.modal', resolve);
            $modal.modal('hide');
        });
    }

    /**
     * @return {boolean}
     */
    get isModalShown() {
        if (!this.$modal) {
            return false;
        }

        const data = this.$modal.data('bs.modal');

        return data && data.isShown || false;
    }

    /**
     * @param {HTMLElement} element
     */
    untilTurboFrameLoad(element) {
        return new Promise(function (resolve) {
            element.addEventListener('turbo:frame-load', resolve, {
                once: true,
            });
        });
    }
}
