import { Controller } from '@hotwired/stimulus';

/**
 * @property {string} successValue
 * @property {string} errorValue
 */
export default class extends Controller {
    static values = {
        success: String,
        error: String,
    };

    initialize() {
        this.formSubmit = this.formSubmit.bind(this);
        this.beforeFetchResponse = this.beforeFetchResponse.bind(this);
    }

    connect() {
        this.element.addEventListener('turbo:before-fetch-response', this.beforeFetchResponse);
        this.element.addEventListener('turbo:submit-end', this.formSubmit);
    }

    disconnect() {
        this.element.removeEventListener('turbo:before-fetch-response', this.beforeFetchResponse);
        this.element.removeEventListener('turbo:submit-end', this.formSubmit);
    }

    /**
     * @param {CustomEvent<{ fetchResponse: { response: { status: int } } }>} event
     */
    beforeFetchResponse(event) {
        const statusBadRequest = 500;
        if (event.detail.fetchResponse.response.status >= statusBadRequest) {
            message.error(this.errorValue);

            event.preventDefault();
        }
    }

    /**
     * @param {CustomEvent<{ success: bool }>} event
     */
    formSubmit(event) {
        if (event.detail.success) {
            message.success(this.successValue || '');
        }
    }
}
