import { Controller } from '@hotwired/stimulus';
import 'bootstrap';

const UPDATE_INTERVAL = 500;
const DEFAULT_SEARCH = '';
const DEFAULT_PAGE_SIZE = '10';
const DEFAULT_PAGE = '1';
const SEARCH_PARAM = 'search';
const PAGE_SIZE_PARAM = 'pageSize';
const PAGE_PARAM = 'page';

// noinspection JSUnusedGlobalSymbols
/**
 * @property {string} baseUrlValue
 * @property {string} paramPrefixValue
 * @property {HTMLElement} pagerFrameTarget
 * @property {boolean} hasPagerFrameTarget
 * @property {HTMLLinkElement} searchLinkTarget
 * @property {boolean} hasSearchLinkTarget
 * @property {HTMLLinkElement} tableFrameTarget
 * @property {boolean} hasTableFrameTarget
 */
export default class extends Controller {

    static values = {
        baseUrl: String,
        paramPrefix: String,
    };

    static targets = [
        'pagerFrame',
        'searchLink',
        'tableFrame',
    ];

    /**
     * @type {?number}
     */
    intervalId = null;

    /**
     * @type {string}
     */
    search = DEFAULT_SEARCH;

    /**
     * @type {string}
     */
    pageSize = DEFAULT_PAGE_SIZE;

    /**
     * @type {string}
     */
    page = DEFAULT_PAGE;

    /**
     * @type {boolean}
     */
    needsToVisit = false;

    initialize() {
        this.onInterval = this.onInterval.bind(this);
        this.updatePager = this.updatePager.bind(this);

        this.searchParam = SEARCH_PARAM;
        this.pageSizeParam = PAGE_SIZE_PARAM;
        this.pageParam = PAGE_PARAM;
    }

    connect() {
        this.intervalId = setInterval(this.onInterval, UPDATE_INTERVAL);
        this.element.addEventListener('app:modal-editor-success', this.updatePager);
        this.element.addEventListener('app:form-confirm-submit', this.updatePager);

        if (this.paramPrefixValue) {
            this.searchParam = this.paramPrefixValue +
                SEARCH_PARAM.replace(/^./, c => c.toUpperCase());

            this.pageSizeParam = this.paramPrefixValue +
                PAGE_SIZE_PARAM.replace(/^./, c => c.toUpperCase());

            this.pageParam = this.paramPrefixValue + 
                PAGE_PARAM.replace(/^./, c => c.toUpperCase());
        }

        const location = new URL(window.location);
        this.search = location.searchParams.get(this.searchParam) || DEFAULT_SEARCH;
        this.pageSize = location.searchParams.get(this.pageSizeParam) || DEFAULT_PAGE_SIZE;
        this.page = location.searchParams.get(this.pageParam) || DEFAULT_PAGE;
    }

    disconnect() {
        this.element.removeEventListener('app:form-confirm-submit', this.updatePager);
        this.element.removeEventListener('app:modal-editor-success', this.updatePager);
        clearInterval(this.intervalId);
    }

    onInterval() {
        if (this.needsToVisit && this.hasPagerFrameTarget) {
            this.needsToVisit = false;
            this.updatePager();
        }
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @param {Event} event
     */
    onSearchInput(event) {
        const search = event.target.value;
        if (this.search !== search) {
            this.search = search;
            this.needsToVisit = true;
        }
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @param {Event} event
     */
    onPageSizeChange(event) {
        const pageSize = event.target.value;
        if (this.pageSize !== pageSize) {
            this.pageSize = pageSize;
            this.needsToVisit = true;
        }
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @param {Event} event
     */
    onPageChange(event) {
        event.preventDefault();
        const href = event.target.closest('a')?.getAttribute('href') ?? '';
        if (!href) {
            return;
        }
        const page = href
            .split('?')[1]
            ?.split('&')
            .find(element => element.indexOf('addressPage=') >= 0)
            ?.split('=')[1] ?? DEFAULT_PAGE;
        if (this.page !== page) {
            this.page = page;
            this.needsToVisit = true;
        }
    }

    updatePager() {
        if (this.hasTableFrameTarget) {
            this.tableFrameTarget.removeAttribute('src');
            this.tableFrameTarget.setAttribute('src', this.getNextUrl());
        } else {
            this.pagerFrameTarget.removeAttribute('src');
            this.pagerFrameTarget.setAttribute('src', this.getNextUrl());
        }
    }

    /**
     * @returns {string}
     */
    getNextUrl() {
        const location = new URL(window.location);
        const to = new URL(this.baseUrlValue);
        for (const p of location.searchParams) {
            to.searchParams.set(p[0], p[1]);
        }

        if (this.search === DEFAULT_SEARCH) {
            to.searchParams.delete(this.searchParam);
        } else {
            to.searchParams.set(this.searchParam, this.search);
        }

        if (this.pageSize === DEFAULT_PAGE_SIZE) {
            to.searchParams.delete(this.pageSizeParam);
        } else {
            to.searchParams.set(this.pageSizeParam, this.pageSize);
        }

        if (this.page === DEFAULT_PAGE) {
            to.searchParams.delete(this.pageParam);
        } else {
            to.searchParams.set(this.pageParam, this.page);
        }

        return to.toString();
    }
}
